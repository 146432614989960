.loader {
    position: relative;
    width: 8rem;
    height: 8rem;
    perspective: 25rem;
  }
  
  .loader div {
    width: 100%;
    height: 100%;
    background:rgb(95, 173, 95);
    position: absolute;
    left: 50%;
    transform-origin: left;
    animation: loader 2s infinite;
  }
  
  .loader div:nth-child(1) {
    animation-delay: 0.15s;
  }
  
  .loader div:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .loader div:nth-child(3) {
    animation-delay: 0.45s;
  }
  
  .loader div:nth-child(4) {
    animation-delay: 0.6s;
  }
  
  .loader div:nth-child(5) {
    animation-delay: 0.75s;
  }
  
  @keyframes loader {
    0% {
      transform: rotateY(0deg);
    }
  
    50%, 80% {
      transform: rotateY(-180deg);
    }
  
    90%, 100% {
      opacity: 0;
      transform: rotateY(-180deg);
    }
  }